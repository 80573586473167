<template>
  <b-container fluid>
    <StrapiEditoComponent></StrapiEditoComponent>
    <StrapiCollectionComponent :collection="collection"></StrapiCollectionComponent>
  </b-container>
</template>

<script>
import StrapiEditoComponent from "../../components/strapi/StrapiEditoComponent.vue";
import StrapiCollectionComponent from "../../components/strapi/StrapiCollectionComponent.vue";
export default {
  name: "StrapiReglementsCampagneView",
  components: { StrapiEditoComponent, StrapiCollectionComponent, },
  computed: {
    collection() {
      return this.$store.getters["strapi/collection"]("reglements-campagne");
    },
  },
};
</script>
